.homeContainer {
  height: 100%;
  display: flex;
  margin-left: 200px;
  margin-top: 200px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
}
.line {
  width: 70px;
  height: 4px;
  background-color: rgb(8, 175, 115, 1);
  margin-bottom: 4rem;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.video video {
  width: 250px;
  height: 250px;
  margin-right: 25px;
  /* border-radius: 50%; */
}
.homeText {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  font-family: sans-serif;
  color: white;
  text-align: left;
}

.homeText h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.homeText p {
  font-size: 16px;
  font-weight: 300;
  width: 50%;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}

.homeBtn {
  border: 2px solid rgb(8, 175, 115, 1);
  padding: 10px 14px;
  cursor: pointer;
  border-radius: 0.5rem;
  font: 16px sans-serif;
  font-weight: bold;
  color: rgb(8, 175, 115, 1);
  background-color: transparent;
  transition: 0.3s;
  margin-bottom: 1.5rem;
}
.homeBtn:hover {
  transition: 0.3s;
  background-color: rgb(8, 175, 115, 1);
  color: white;
}
.homeTechs {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  font-family: sans-serif;
  color: white;
  text-align: left;
}
.homeTechs h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.techs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
}
.tech {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
}
.tech img {
  border-radius: 10%;
  width: 125px;
  height: 125px;
  resize: cover;
  margin-bottom: 1rem;
}
.tech h3 {
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
}

.homeExp {
  margin-top: 100px;
  margin-bottom: 100px;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  font-family: sans-serif;
  color: white;
  text-align: left;
}
.homeExp h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.exps {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
}
.exp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
}
.exp h1 {
  font-size: 56px;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: rgb(8, 175, 115, 1);
  text-shadow: 3px 3px 0 rgb(0, 0, 0), -1px -1px 0 rgb(0, 0, 0),
    1px -1px 0 rgb(0, 0, 0) -1px 1px 0 rgb(0, 0, 0), 1px 1px 0 rgb(0, 0, 0);
  margin-right: 1rem;
}
.homeClients {
  height: 40vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-family: sans-serif;
  color: white;
  text-align: left;
  margin-bottom: 100px;
}
.homeClients h5 {
  font-weight: bold;
  margin-right: 5rem;
  width: 250px;
  font: 1.5rem sans-serif;
}
.homeClients hr {
  width: 250px;
  height: 1px;
  background-color: gray;
  margin-bottom: 1rem;
  color: gray;
}

.clients {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
}
.workDetail {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}
.workDetail h5 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: white;
}
.workDetail span {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: rgb(8, 175, 115, 1);
}
.works {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  flex: 1;
  width: 100%;
  height: 100%;
}
.work {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  margin-top: 50px;
  width: 90%;
  height: 100%;
}
.dropdown {
  display: none;
  position: relative;
  /* slide out on display */
  animation: slideout 0.2s ease;
}
@keyframes slideout {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes slidein {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.active {
  display: flex;
  flex-direction: column;
  animation: slidein 0.2s ease;
  z-index: -1;
}

.work ul {
  margin-top: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.work ul li {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
  color: white;
}
.work h2 {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  letter-spacing: 1px;
  color: white;
}
.work span {
  font-size: 1rem;
  letter-spacing: 0.8px;
  margin-bottom: 0.5rem;
  color: white;
  font-weight: 400;
}
.seeMore {
  background: none;
  color: rgb(8, 175, 115, 1);
  text-decoration: underline;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}
.dull {
  color: rgb(8, 175, 115, 0.5);
}
.bg{
  background: url('.././assets/bg.jpg');
  opacity: 0.5;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -999;
}
@media only screen and (min-device-width: 300px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .homeContainer {
    height: 100%;
    display: flex;
    margin-left: 95px;
    margin-top: 180px;
    display: flex;
    flex-direction: column;
  }
  .homeText h1 {
    width: 87%;
    font-size: 1.2rem;
}
  .homeTechs h1 {
    font-size: 1.2em;
  }
  .homeText p {
    color: white;
    width: 90%;
    font-size: 0.9rem;
}
  
  .homeTechs {
    width: 70%;
  }
  .techs {
    flex-direction: column;
  }
  .tech img {
    width: 50px;
    height: 50px;
  }
  .homeContent {
    width: 100%;
    height: 100%;
  }
  .line {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .homeExp h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  .exps {
    flex-direction: column;
  }
  .exp {
    width: 80%;
    justify-content: flex-start;
    margin-top: 2em;
  }
  .homeClients {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .homeClients h5 {
    font-weight: bold;
    margin-left: 3rem;
    /* width: 250px; */
    font: 1rem sans-serif;
  }
  .clients {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .client {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .homeClients hr {
    margin-bottom: 3rem;
    margin-top: 3rem;
    width: 8em;
  }
  .workDetail h5 {
    font-size: 1rem;
    color: white;
  }
  .workDetail span {
    font-size: 0.8rem;
    width: 70%;
  }
  .works {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .work span {
    width: 90%;
    font-size: 0.8rem;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .homeContainer {
    height: 100%;
    display: flex;
    margin-left: 110px;
    margin-top: 180px;
    display: flex;
    flex-direction: column;
  }
  .works{
    margin-bottom: 50px;
  }
  .clientsContent{
    
    margin-top:100px;
  }
  .techs,.exps {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;}
    hr{
      display: none;
    }
    .homeClients {
    width: 100%;
    height: 100%;
    flex-direction: column;
    }
    .clients {
      margin-bottom: 100px;
      margin-top: 100px;}
 }
