.projectContainer {
    height: 100%;
    display: flex;
    margin-left: 150px;
    margin-top: 200px;
    margin-bottom: 100px;
    /* margin-right: 100px; */
    display: flex;
    flex-direction: column;
    margin-right: 100px;
  }
  .projects{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-top: 150px;
    width: 100%;
    height: 100%;

  }
  .project{
    border-radius: 15px;
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 50px;
    background: url(../assets/alex-kotliarskyi-QBpZGqEMsKg-unsplash.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    /* z-index: -8 ; */
    margin-top: 200px;
  }
  .projectText{
    flex-direction: column;
    display: flex;
  }
  .projectContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 50px;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4px);
    border-radius: 10px;
  }
  .projectText h1{
    font-size: 1.4rem;
    font-weight: bold;
    color: white;
  }
  .projectText span{
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .projectContainerContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    width: 100%;
    height: 100%;
  }
  @media only screen and (min-device-width: 300px) and (max-device-width: 744px) and (-webkit-min-device-pixel-ratio: 2) {
    
    .projectContainer{
      /* margin-top: 80px; */
      margin-left: 90px;
      margin-top: 200px;
    }
    .projects{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      width: 140%;
      height: 100%;
      margin-top:420px;
    }
    .project{
      margin-top: 70px;
      width: 100%;
      height: 100%;
      padding: 20px;
    }
    .projectContent{
      padding: 20px;
    }
    .projectText h1{
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  
  }