.thoughtsContainer {
     /* padding: 90px; */
     padding-left: 115px;
     padding-top: 200px;
     height: 100%;
     display: flex;
     /* margin-left: 83px; */
     /* margin-top: 139px; */
     margin-bottom: 100px;
     /* margin-right: 100px; */
     display: flex;
     flex-direction: column;
}
/* .homeContent {
  margin-bottom: -135px;
} */
.date {
  font-size: 0.7rem;
  /* font-weight: bold; */
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
  color: white;
}
.thoughts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.thought {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  /* margin-top: 50px; */
  width: 80%;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 20px 20px 0px 20px;
}
.thought img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 10px;
}
.thought h2 {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.2rem;
  margin-bottom: 0.1rem;
  color: white;
}
@media only screen and (min-device-width: 300px) and (max-device-width: 744px) and (-webkit-min-device-pixel-ratio: 2) {
  .thoughtsContainer {
    margin-left: 100px;
  }
  .homeText h1 {
    width: 88% !important;
  }
  .homeContent {
    margin-top: 45px;
  }
  .thoughts {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .thought {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    /* margin-top: 50px; */
    width: 90%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 20px 20px 0px 20px;
  }
  .thought img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  .thought h2 {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.2rem;
    margin-bottom: 0.1rem;
    color: white;
  }
  .thoughtsContainer{
    margin-left: 0px!important;
    /* padding: 90px; */
    padding-left: 84px;
    padding-top: 200px;
    /* height: 100%; */
    /* display: flex; */
    /* margin-left: 83px; */
    /* margin-top: 139px; */
    /* margin-bottom: 100px; */
    /* margin-right: 100px; */
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .thoughts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5rem;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .thought {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    /* margin-top: 50px; */
    width: 80%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 20px 20px 0px 20px;
  }
  .thought img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  .thought h2 {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.2rem;
    margin-bottom: 0.1rem;
    color: white;
  }
}
